import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Element from 'element-ui'
Vue.config.productionTip = false
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import store from './store'
import RightToolbar from "@/components/RightToolbar"
Vue.component('RightToolbar', RightToolbar)



Vue.prototype.$axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.fontURL = process.env.VUE_APP_API_FONT_URL;
axios.defaults.refeshURL = process.env.VUE_APP_API_REFRESH_URL;
axios.defaults.timeout = 180000;

Vue.use(Element)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
