import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    component: () => import('@/views/error/404')
  },
  {
    path: '/',
    component: () => import('@/views/index'),
    children: [
      {
        path: '/',
        component: () => import('@/views/index_v1'),
      },
      {
        path: '/charts/btc-netflow-usd',
        component: () => import('@/views/charts/btc-netflow-usd'),
      },
      {
        path: '/charts/btc-bum',
        component: () => import('@/views/charts/btc-bum'),
      },
      {
        path: '/charts/eth-netflow-usd',
        component: () => import('@/views/charts/eth-netflow-usd'),
      },
      {
        path: '/charts/btc-distribution',
        component: () => import('@/views/charts/btc-distribution'),
      },
      {
        path: '/table/:dynamicParam-24q1-value',
        component: () => import('@/views/table/24q1_value'),
      },
      {
        path: '/table/btc-netflow-usd',
        component: () => import('@/views/table/btc-netflow-usd'),
      },
      {
        path: '/table/eth-netflow-usd',
        component: () => import('@/views/table/eth-netflow-usd'),
      },
      {
        path: '/table/:dynamicParam-24q1',
        component: () => import('@/views/table/24q1'),
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
